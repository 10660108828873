import React from "react";
import ShowsRows from "../components/ShowsRows";
import { shows_data } from "./shows_data";


const ShowsPage = () => (
    <div className="page-wrapper">
        <h2>Shows</h2>

        <h3>Some pre-lockdown shows are listed below.</h3>

        <p className="App-paragraph">The page will get updated when the band starts doing 
        live shows again!</p>

        <div className="App-div">
            <table className="shows-table">
                <thead>
                    <tr>
                    <th className="shows-th">Date</th>
                    <th className="shows-th">Venue</th>
                    <th className="shows-th">Bands</th>
                    <th className="shows-th">Poster</th>
                    </tr>
                </thead>
                <tbody>
                    <ShowsRows shows={shows_data} />
                </tbody>

            </table>
        </div>

    </div>
);

export default ShowsPage;
