export const shows_data = [
    { 
        "id": "20190901",
        "show_date": "2019/09/01", 
        "show_time": "4:00 PM", 
        "ages": "21+", 
        "venue": "Greenwood Music Crawl - Houndstooth Pub", 
        "venue_url": "http://www.greenwoodmusiccrawl.com/", 
        "location_url": "https://www.google.com/maps/place/Hounds+Tooth+Public+House/@47.6923047,-122.3555302,15z/data=!4m5!3m4!1s0x0:0x6f9a21ef50ad4d9c!8m2!3d47.6923047!4d-122.3555302",
        "location_text": "8551 Greenwood Ave N #5, Seattle, WA 98103", 
        "bands":
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors",
                    "band_text": "plus a ton of other bands!"
                }
            ], 
        "poster_url": "images/SHOWS/greenwood2019s.jpg", 
        "event_url": "https://www.facebook.com/events/1111321369063642/"
    }, 
    { 
        "id": "20170928",
        "show_date": "2017/09/28", 
        "show_time": "9:00 PM", 
        "ages": "All Ages", 
        "venue": "Georgetown Music", 
        "venue_url": "http://georgetownmusicstore.com", 
        "location_url": "https://www.google.com/maps/place/Georgetown+Music/@47.5481635,-122.3163827,15z/data=!4m5!3m4!1s0x0:0xe1c0914dc00acc15!8m2!3d47.5481635!4d-122.3163827",
        "location_text": "6111 13th Ave S, Seattle WA", 
        "bands" :
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors",
                },
                {
                    "name": "Knuckles",
                    "band_url": "http://www.knuckles206.com",              
                }  
            ],
        "poster_url": "images/SHOWS/gtownmusics.jpg", 
        "event_url": "https://www.facebook.com/events/1342018629254555/1369149699874781/"
    }, 
    { 
        "id": "201970805",
        "show_date": "2017/08/05", 
        "show_time": "9:00 PM", 
        "ages": "21+", 
        "venue": "Darrell's Tavern", 
        "venue_url": "http://www.darrellstavern.com ", 
        "location_url": "http://maps.google.com/maps?f=q&hl=en&q=18041+Aurora+Ave+N+Shoreline,+WA",
        "location_text": "18041 Aurora Ave N, Shoreline, WA", 
        "bands": 
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors",
                },
                {
                    "name": "Valerie Cavell",
                    "band_url": "https://www.facebook.com/profile.php?id=100016257437793&fref=mentions&pnref=story",               
                },
                {
                    "name": "The Macks",
                    "band_url": "https://www.facebook.com/TheMacksBand/"
                },
                {
                    "name": "The Evanstones",
                    "band_url": "https://www.facebook.com/TheEvanstones/"
                }  
            ],
        "poster_url": "images/SHOWS/darrells2017b.jpg", 
        "event_url": "https://www.facebook.com/events/873104279521775/"
    }, 
    { 
        "id": "20151114",
        "show_date": "2015/11/14", 
        "show_time": "9:00 PM", 
        "ages": "21+", 
        "venue": "Flights Pub", 
        "venue_url": "http://www.facebook.com/Flights-Pub-235888056435979/", 
        "location_url": "https://www.google.com/maps/place/Flights+Pub/@47.9292553,-122.2196295,15z/data=!4m2!3m1!1s0x0:0xf5d1ac1927cc0b19",
        "location_text": "7601 Evergreen Way, Everett, WA", 
        "bands": 
            [
                {
                    "name": "John Paul and The Apostles",
                    "band_url": "https://www.facebook.com/johnpaulandtheapostles/" 
                },
                {
                        "name": "Brad Yaeger and The Night Terrors",
                        "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors"        
                },
                { 
                    "name": "Wyatt Olney and the Wreckage",
                    "band_url": "https://www.facebook.com/wyattolneyandthewreckage/"
                },
                {
                    "name": "Aether Kid",
                    "band_url": "https://www.facebook.com/aetherkid/"
                }
            ],
        "poster_url": "images/SHOWS/flights2015c.jpg ", 
        "event_url": "https://www.facebook.com/events/1661213827424190/"
    }, 
    { 
        "id": "20150919",
        "show_date": "2015/09/19", 
        "show_time": "9:00 PM", 
        "ages": "21+", 
        "venue": "Darrell's Tavern", 
        "venue_url": "http://www.darrellstavern.com ", 
        "location_url": "http://maps.google.com/maps?f=q&hl=en&q=18041+Aurora+Ave+N+Shoreline,+WA",
        "location_text": "18041 Aurora Ave N, Shoreline, WA", 
        "bands": 
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors",
                }
            ],
    }, 
    { 
        "id": "20150710",
        "show_date": "2015/7/10", 
        "show_time": "6:00 PM", 
        "ages": "21+", 
        "venue": "Substation", 
        "venue_url": "http://www.facebook.com/substationseattle", 
        "location_url": "https://www.google.com/maps/place/645+NW+45th+St,+Seattle,+WA+98107",
        "location_text": "645 NW 45th St, Seattle, WA 98107", 
        "bands": 
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors",
                },
                {
                    "name": "Poetry Assassins",
                    "band_url": "https://www.facebook.com/poetryassassins"
                },
                {
                    "name": "Patrick Nehoda",
                    "band_url": "https://www.facebook.com/ThePatrickNehoda"
                }
            ], 
        "poster_url": "images/SHOWS/substation2015s.jpg", 
        "event_url": "https://www.facebook.com/events/1442705179368463/"
    }, 
    { 
        "id": "20150619",
        "show_date": "2015/6/19", 
        "show_time": "9:00 PM", 
        "ages": "21+", 
        "venue": "Skylark", 
        "venue_url": "http://www.skylarkcafe.com/", 
        "location_url": "http://www.skylarkcafe.com/directions.html",
        "location_text": "3803 Delridge Way SW, Seattle, WA 98106", 
        "bands": 
            [
                { 
                    "name": "Brad Yaeger and The Night Terrors",
                    "band_url": "http://www.reverbnation.com/bradyaegerandthenightterrors"
                },
                {
                    "name": "The Flying Tortugas",
                    "band_url": "http://www.reverbnation.com/theflyingtortugas"
                }
            ]

    } 
]