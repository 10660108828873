import React from 'react';
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import ShowsPage from './pages/ShowsPage';
import NotFoundPage from './pages/NotFoundPage';

import './App.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <NavBar />
        <div id="page-body">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/services" component={ServicesPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/gallery" component={GalleryPage} />
            <Route path="/shows" component={ShowsPage} />
            <Route component={NotFoundPage} />
          </Switch>
          
        </div>

      </div>
    </Router>

  );
}

export default App;
