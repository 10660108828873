import React from 'react';

const ServicesPage = () => (
    <div className="page-wrapper">
        <h2>Services - Pedal Repairs and Mods</h2>
        <ul className="repairs-outer-list">
            <li>Repairs of broken units:</li>
            <ul className="repairs-list-item">
                <li>broken footswitches</li>
                <li>pedal doesn't power up</li>
                <li>pedal powers up but doesn't pass signal</li>
                <li>LEDs not lighting up</li>
                <li>scratchy/noisy potentiometers</li>
                <li>loose input/output jacks</li>
            </ul>
            <li>Pedal mods</li>
            <ul className="repairs-list-item">
                <li>Line 6 DL4 switch upgrades</li>
                <li>restoring TS-9 to TS-808</li>
                <li>cleaning up tone of Boss DS-1</li>
                <li>addition of DC power jacks to older pedals</li>
                <li>changing color of LED indicators</li>
            </ul>              
            <li>Custom builds of clones</li>
            <li>Pedal re-housing</li>
        </ul>

        <p className="App-paragraph">
          Before and after photos of Russian Big Muff that was 
          repaired and cleaned up.
        </p>
        <p className="App-paragraph">
        <img src="images/REPAIRS/bigmuff04small.jpg" alt="Russian Big Muff" /> 
        </p>
        
    </div>
);

export default ServicesPage;