import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RandomPedalPhoto from '../components/RandomPedalPhoto';
import pedalphotos from '../components/PedalPhotosData';
import VideoList from '../components/VideoList';

// TODO:
// Change yellow color to less bright
// Sprinkle some font aweseome icons (video, image, calendar, martini)
// Pin the header row in the shows page so that it stays in place as you scroll the page. 
// Redo logo with an Google font

class HomePage extends Component {

    constructor() {
        super();
        const initialPhoto = this.randomPhoto();
        this.state = {
            photo: initialPhoto.photo,
            caption: initialPhoto.caption
        };
    }

    randomPhoto() {
        const randomIndex = Math.floor(Math.random() * pedalphotos.length);
        return pedalphotos[randomIndex];
    }

    handleClick = () => {
        const newPhoto = this.randomPhoto();
        this.setState({
            photo: newPhoto.photo,
            caption: newPhoto.caption
        });
    }

    render() {
        return (
            <div className="page-wrapper">
            <h2>Zorro Music Devices</h2>
            <h3>Guitar pedal repair and mods in Seattle.</h3>
                <div className="row">
                    <div className="column">        
                        <p className="App-paragraph">Specializing in vintage and classic pedals and 
                        other music electronics since 2009.
                        </p>
                        <p className="App-paragraph">Your guitar pedals have value. If they are broken, or dying, 
                            or 'not-quite-right' then they should be fixed up and repaired! 
                        </p>
        
                        <p className="App-paragraph">As a guitar player I know that gear should be working solid and sounding awesome.
                            My experience and training in analog electronics can work 
                            some magic on your electronic music equipment. 
                        </p>

                        <h4>Update for 2024:</h4>

                        <p className="App-paragraph">I'm taking a break from pedal work for 2024.</p>

                        <p className="App-paragraph">My day job is keeping me busy.
                            I'd like to focus on guitar woodshedding in my spare time.</p>

                        <p className="App-paragraph">The last 15 years of pedal work have been awesome.
                            Hopefully I will get a chance to resume the pedal work later.</p>

                        <h3>Shows</h3>
        
                        <p className="App-paragraph">I play lead guitar 
                        with <a href="http://www.facebook.com/Bradyaegerandthenightterrors/">Brad Yaeger 
                        and the Night Terrors.</a>
                        </p>
        
                        <p className="App-paragraph">No shows are scheduled during the 2020-2021 pandemic. 
                        The band is dying to get back to playing live as soon as possible!
                        </p>
        
                        <p className="App-paragraph">Any shows will be listed 
                        on <Link to="/shows">the shows page</Link>.
                        </p>

                        <VideoList />
                    </div>

                    <div className="column">
                        <div className="green-column">
                            {/* Random example photo */}
                            {/* Inspired by https://www.golangprograms.com/random-quote-generator.html */}
                            <RandomPedalPhoto 
                                {...this.state} />
                            <p className="App-paragraph">
                                <button 
                                    id="newphoto"
                                    onClick={this.handleClick}>
                                    View random pedal
                                </button>
                            </p>
                        </div>
                    </div>
                </div>       
            </div>
        );
    }
 
}

export default HomePage;