import React from 'react';

//TODO: Shorten content. Add photos. 

const AboutPage = () => (
    <div className="page-wrapper">
        <h2>The story of Zorro Music Devices</h2>

        <p className="App-paragraph">
        Kiss did it. The look. The sounds.  
        Ace Frehley's guitar solos. Heavy. Got me hooked on guitar when I was 13 years old.    
        </p>

        <p className="App-paragraph">
        By the end of high school I was doing plenty of shows with the Sharx. 
        The material was hard rock covers with a few originals. That's us
        in about 1980 or so. Scooter, Rodd, Zorro, and Nims.   
        </p>
        <p className="App-paragraph">
        <img src="images/ABOUT/sharx01.png" alt="Sharx 1980" /> 
        </p>

        <p className="App-paragraph"> 
        It was about this time that I started to get an interest in the electronics and 
        the amplifiers. The stuff that was actually making the sounds! 
        I studied analog electronics at Bellingham Technical College.
        </p>

        <p className="App-paragraph">
        Musically, I was ready to go in a different musical direction.  
        I hooked up with the heavy dirge unit DSML in Seattle.  
        </p>

        <p className="App-paragraph">
        Below: DSML at the Metropolis, February 1984. 
        That's me on the right with an Ibanez Artist going through a Fender Bassman. 
        Photo by K. Shroud. 
        </p>
        <p className="App-paragraph">
        <img src="images/ABOUT/dsml_met.jpg" alt="DSML 1984" />  
        </p>

        <p className="App-paragraph">
        College and my career took me out of the music scene for several years. 
        In 2003 I picked up a Harmony acoustic and did the coffee-shop-singer-songwriter thing. 
        By 2009 I wanted to go heavy again. I hooked up with
        Brad Yaeger and the Night Terrors. 
        </p>

        <p className="App-paragraph">
        A bit of time "between jobs" gave me some time to rediscover my 
        passion for electronics and guitar effects. 
        And I've been totally hooked on pedal repairs, pedal mods, and building clone kits. 
        </p>

       <p className="App-paragraph">
       Below: Brad and the Night Terrors hit it hard at Darrells Tavern. 
       I'm playing a Mexi-strat through a tube Fender Concert.
       Photo by Patti Mullen.
       </p>
       <p className="App-paragraph">
       <img src="images/ABOUT/terrors_darrells.jpg" alt="Night Terrors at Darrells" />
       </p>
    </div>
);

export default AboutPage;