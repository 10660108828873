import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
    <div className="header" >
      <Link to="/"><img src="images/zorro-logo-medium.png" alt="logo" /></Link>
      <h1>Jorge Zorro</h1>
    </div>
);

export default Header;