import React, { Component } from 'react';

class RandomPedalPhoto extends Component {

    render() {
        const pathToPhoto = "images/GEAR/" + this.props.photo;
        return (
            <div>
            <p className="App-paragraph"> 
                Example repair/mod job: {this.props.caption}
            </p>
            <p className="App-paragraph">
                <img src={pathToPhoto} alt="example pedal repair" />  
            </p>

            </div>
        );
    }
}

export default RandomPedalPhoto ;