const pedalphotos=[
    {
        "photo": 'cov01bmp.jpg',
        "caption": 'Russian Big Muff Pi'
    },
    {
        "photo": 'cov02df2.jpg',
        "caption": 'Super Feedbacker'
    },
    {
        "photo": 'cov03dl4.jpg',
        "caption": 'Line 6 DL4'
    },
    {
        "photo": 'cov04fx50.jpg',
        "caption": 'DOD FX50'
    },
    {
        "photo": 'cov05fx57.jpg',
        "caption": 'DOD FX57'
    },
    {
        "photo": 'cov06fx65.jpg',
        "caption": 'DOD FX65 with vibrato mod'
    },
    {
        "photo": 'cov07maes.jpg',
        "caption": 'Gibson Maestro Fuzztone'
    },
    {
        "photo": 'cov08ad80.jpg',
        "caption": 'Maxon analog delay'
    },
    {
        "photo": 'cov09ross.jpg',
        "caption": 'Ross compressor'
    },
    {
        "photo": 'cov10ts808.jpg',
        "caption": 'Ibanez TS808 tube screamer'
    },
    {
        "photo": 'cov11sd1.jpg',
        "caption": 'Boss SD-1'
    }
]

export default pedalphotos