import React, { Component } from "react";
import { format } from 'date-fns';

export default class ShowsRows extends Component {

    render() {
        if (this.props.shows && this.props.shows.length > 0) {

            return (
                this.props.shows.map((item) => (
 
                    /* table row for show */
                    <tr key={item.id}>
                        <td className = "shows-td">
                            {/* day of week, mon-day-year, show time, ages */}
                            {format(new Date(item.show_date), 'EEEE') }
                            <br />{format(new Date(item.show_date), 'PP')}
                            <br />{item.show_time}
                            <br />{item.ages}
                        </td> 
                        <td className = "shows-td"><a target="_blank" rel="noopener noreferrer" href={item.venue_url}>{item.venue}</a>
                            { /*<br /><a target="_blank" href={item.location_url}>{item.location_text}</a> */}
                        </td>
                        <td className = "shows-td">
                            {item.bands.map((band, i)  => (
                                <div key = {i}>
                                    <a target="_blank" rel="noopener noreferrer" href={band.band_url}>{band.name}</a>
                                    {band.band_text && <p>{band.band_text}</p> }
                                </div>
                            ))}

                        </td>
                        <td className = "shows-td">
                            {item.event_url ?
                                <a target="_blank" rel="noopener noreferrer" href={item.event_url}> 
                                <img src={item.poster_url} alt="poster for show" />
                                </a>
                                :
                                <p> </p>
                            }

                        </td>
                    </tr>
                ))
            )
        } else {
            return (<tr><td>Loading...</td></tr>)
        }
    }
}