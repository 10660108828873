export const GalleryData = [
    {
      src: "images/GALLERY/bossdf2.jpg",
      width: 4,
      height: 3,
      title: 'DF-2'
    },
    {
      src: "images/GALLERY/bossrps10delmod.jpg",
      width: 1,
      height: 1,
      title: 'Super-delay knob added to a Boss RPS-10'
    },
    {
      src: "images/GALLERY/ds1_tone_clip.jpg",
      width: 4,
      height: 3,
      title: 'Tone and clipping mods for a DS-1'
    },
    {
      src: "images/GALLERY/dl4_switches_01.jpg",
      width: 4,
      height: 3,
      title: 'Heavy duty mechanical switches added to a DL-4'
    },
    {
      src: "images/GALLERY/ds1eye.jpg",
      width: 4,
      height: 3,
      title: 'Standard Keeley Seeing Eye mods for a DS-1'
    },
    {
      src: "images/GALLERY/fl9_fire.jpg",
      width: 4,
      height: 3,
      title: 'A repaired Ibanez DL-9 recovered from a fire'
    },
    {
      src: "images/GALLERY/freeze.JPG",
      width: 4,
      height: 3,
      title: 'Freeze pedals with mode switches removed and hardwired for latch mode'
    },
    {
      src: "images/GALLERY/maxon01.jpg",
      width: 4,
      height: 3,
      title: 'Older delay pedals often need re-capping and clock adjustments'
    },
    {
      src: "images/GALLERY/mxr_kmd_dod.jpg",
      width: 4,
      height: 3,
      title: 'A collection of an MXR, KMD, and DOD needing repairs'
    },
    {
      src: "images/GALLERY/re201_01.JPG",
      width: 4,
      height: 3,
      title: 'Massive cleaning, de-magnetizing, and alignment for this old Space Echo'
    },
    {
      src: "images/GALLERY/rosscomp.jpg",
      width: 4,
      height: 3,
      title: 'Vintage Ross compressor that needed repairs'
    },
    {
      src: "images/GALLERY/ts808.jpg",
      width: 4,
      height: 3,
      title: 'Basic low-end tone mod for this classic TS-808'
    },
    {
      src: "images/GALLERY/tubescreamerreiss.jpg",
      width: 4,
      height: 3,
      title: 'Foot switch repairs for a Tubescreamer'
    },
    {
      src: "images/GALLERY/wah_to_exp.jpg",
      width: 4,
      height: 3,
      title: 'A Dunlop bass wah converted to an expression pedal'
    }
  ];