import React from 'react';

const VideoList = () => (
    <div className="videolist" >
      <h4>Band Videos</h4>
      <p className="App-paragraph">These are a couple of my favorites:</p>
      <div className="videolink">
          <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/watch?v=2XEErQr4a7k">
          <img src="images/HOME/oldtree.jpg" alt="Thumbnail for video" width="200"/><br />Old Tree</a> 
          , studio recording, video by Brad Strain
      </div>
      <div className="videolink">
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=muUyhM7nqqY">
          <img src="images/HOME/sarahjane.jpg" alt="Thumbnail for video" width="200"/><br />Sarah Jane</a>
          , live at Slim's, video by Jess Sweetman
      </div>


      <p className="App-paragraph">
      <a target="_blank" rel="noopener noreferrer" href="http://www.squatterrecords.com/videos/">
      Video section of Squatter Records</a> has a ton more videos!
      </p>
    </div>
);

export default VideoList;